var FormDropzone = function () {


    return {
        //main function to initiate the module
        init: function () {  

            Dropzone.options.myDropzone = {
                init: function() {

                    this.on("addedfile", function(file) {
                        // Create the remove button
                        var _this = this;
                        var removeButton = Dropzone.createElement("<button class='btn btn-sm btn-block'>Remove file</button>");
                       // var _this = this;
                        // Capture the Dropzone instance as closure.



                          // Remove the file preview.



                                /*  */
                            _this.on("success", function( responseText) {
                                var photo_id=JSON.parse(responseText.xhr.response).photo_id;
                                // Listen to the click event

                                removeButton.addEventListener("click", function(e) {

                                    // Make sure the button click doesn't submit the form:
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _this.removeFile(file);
                                    $.ajax({
                                        url: 'http://127.0.0.1:8000/api/vehicule/photos/delete/'+photo_id,
                                        type: 'DELETE',
                                        success: function(result) {

                                        }
                                    });

                            });





                          // If you want to the delete the file on the server as well,
                          // you can do the AJAX request here.
                        });

                        // Add the button to the file preview element.
                        file.previewElement.appendChild(removeButton);
                    });

                }            
            }
        }
    };
}();